import * as React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { ContentSection } from '@digigear/elements';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

const StyledContentSection = styled(ContentSection)`
  h1,
  h2,
  h3,
  h4 {
    margin-top: 32px;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 10px;
  }
`;

const Template = ({ data }) => {
  const { markdownRemark } = data;
  return (
    <Layout>
      <Seo title={markdownRemark.frontmatter.title} />
      <StyledContentSection narrow>
        <h1>{markdownRemark.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
      </StyledContentSection>
    </Layout>
  );
};

export default Template;

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;
